import { useState, Fragment, useEffect } from 'react'
import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import { HomeIcon, UserGroupIcon, CameraIcon, Bars3Icon, XMarkIcon, ChevronDownIcon,ChevronUpIcon, ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
import { FacebookProvider, Page, Like } from 'react-facebook';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Calendar() {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = [
    { name: 'Home', href: '/'},
    // { name: 'Location', href: '#location' },
    { name: 'Location & Hours', href: '/#hours' },
    { name: 'Sponsors', href: "/#sponsors" },
    { name: 'FAQ', href: "/#faq" },
    { name: 'Safety & Rules', href: '/#safety' },
    // { name: 'Rules', href: '#rules' },
    { name: 'Facebook', href: 'https://www.facebook.com/profile.php?id=61563937867187'},
    { name: '2024 Calendar', href: '/calendar' },
    { name: 'Contact Us', href: '/#contact' },
  ];

  const [faq, setFAQ] = useState([
    {
      question: "Hop Garden Haunted Basement",
      img: "/images/hopgarden.png",
      answer: "Head to The Hop Garden Fridays and Saturdays for a bite and brew, and venture down into the Haunted Basement if you dare. This experience is exclusive to Hop Garden patrons and not open to the general public. We kindly request a $1-5 donation, all of which will benefit our friends at Aware In Evansville.",
      date: "Oct 11, 12, 18, 19, 25, 26, 31",
      time: "5-8pm",
      link: "https://www.facebook.com/HopGardenEvansville",
      open: false
    },{
      question: "Trunk or Treat @ Infinity Martial Arts",
      img: "/images/infinity.png",
      answer: "When we were kids, Halloween was one of our most favorite holidays! And we’re pretty sure it’s your kids\' too. They get hyped and happy when they hear a Halloween party is coming…and it\'s located right in the neighborhood!",
      date: "Oct 19",
      time: "1:30-3:30pm",
      link: "https://www.facebook.com/events/1167569941215518/",
      open: false
    },{
      question: "Underground Music Halloween Bash",
      img: "/images/eum.png",
      answer: "This is our final OUTDOOR show of the 2024 season and we are bringing back our (touring) Kansas friends to celebrate!!! Yes that's right, we are returning to 344 Garfield St. in our lovely city of Evansville, WI. for this HALLOWEEN show AND you guessed it, there will be dancing, heavy swaying AND a best costume award.",
      date: "Oct 19",
      time: "5pm",
      link: "https://www.facebook.com/events/853948469877620/",
      open: false
    },
    {
      question: "ECT Science Fiction Radio Show",
      img: "/images/ect.jpg",
      answer: "This year we are aiming our focus on science fiction. We will present two stories by Ray Bradbury – There Will Come Soft Rains and Zero Hour. The evening will conclude with a telling of War Of The Worlds. No radio production would be complete without our audience favorite custom jingles scattered throughout the show. Mark your calendars and join us for an evening of fun!",
      date: "Oct 24, 25, 26",
      time: "7pm & 3pm shows available",
      link: "https://ecttickets.ludus.com/index.php?show_id=200465004",
      open: false
    },
    {
      question: "Merchant Trick or Treat",
      img: "/images/ecc.png",
      answer: "Merchant Trick or Treat & Scavenger Hunt! Dress in your costume and visit businesses for a trick or treat! Scavenger hunt to add to the fun!",
      date: "Oct 26",
      time: "11am-2pm",
      link: "https://www.facebook.com/events/1167569941215518/",
      open: false
    },{
      question: "Jack-o-Lantern Jamboree",
      img: "/images/evansvilleschools.png",
      answer: "Games, food, raffle and more, costumes encouraged! Cash only accepted.",
      date: "Oct 26",
      time: "5-7pm",
      link: "https://www.facebook.com/EvansvilleK8PTO",
      open: false
    },{
      question: "Forsaken Souls Free Haunted House",
      img: "/images/forsakensouls-shirt.png",
      answer: "Forsaken Souls is a small non-commercial home haunt in Evansville, WI. Join us this Halloween for a night full of frights!",
      date: "Oct 27, 31",
      time: "Kid Friendly & Full Scare Hours",
      link: "https://www.forsakensouls.com/",
      open: false
    },{
      question: "Trapper's Halloween Bingo",
      img: "/images/trappers.png",
      answer: "Costume night. Trappers giving out $50, $30 & $20 gift certificates for prizes. Come join us for some bingo fun. BBB has 100% payouts. Bring your daubers if you have them. Please remember your DOLLAR BILLS. Come early and enjoy our daily specials.",
      date: "Oct 30",
      time: "6-8pm",
      link: "https://www.facebook.com/events/trappers-bar-and-grill/trappers-halloween-bingo/453509824377289/",
      open: false
    },{
      question: "Evansville Trick or Treat",
      img: "/images/CoE.png",
      answer: "Put on your costumes and enjoy the night with your neighbors!",
      date: "Oct 31",
      time: "5-7pm",
      link: "",
      open: false
    },{
      question: "Night Owl Costume Trivia Party",
      img: "/images/nightowl.png",
      answer: "Prizes will be awared for the best team costumes, best individual costumes, and a spooooky big grand prize for trivia.",
      date: "Oct 31",
      time: "7pm",
      link: "https://www.facebook.com/nightowlsportspubandeatery",
      open: false
    }
  ])

  let [scrolled, setScrolled] = useState(false);
  let [scrolledLarge, setScrolledLarge] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', (event) => {
      let scrollTop = event.target.scrollingElement.scrollTop;
      let elementPosition = document.getElementById('logos').offsetTop;

      if (scrollTop > document.getElementById('logos').offsetTop + 100) {
        setScrolledLarge(true);
      }else{
        setScrolledLarge(false);
      }

      if (scrollTop > elementPosition) {
        setScrolled(true);
      }else{
        setScrolled(false);
      }
      
    });
  }, []);

  

  return (
    <>
    <main id="main"><FacebookProvider appId="360440440445559">

      <div className="bg-slate-900">
        <header className={"fixed inset-x-0 top-0 z-50 transition-colors duration-500 " + (scrolled ? "bg-slate-800 shadow-2xl" : (scrolledLarge ? "lg:bg-slate-800 lg:shadow-2xl" : ""))} >
          <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
            <div className="flex lg:flex-1">
            <span className={"transition-opacity duration-500 " + (scrolled ? "opacity-100" : (scrolledLarge ? "opacity-0 lg:opacity-100" : "opacity-0"))}>
              <span className="sr-only">Forsaken Souls Haunted Attraction</span>
              <HashLink to="#home" className="flex flex-row items-center gap-x-5"><img
                alt=""
                src="/images/forsakensouls-shirt.png"
                className="h-6 w-auto"
              /><img
              alt=""
              src="/images/spookeasy-transparent.png"
              className="h-6 w-auto"
            /></HashLink>
            </span>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(true)}
                className="-m-2.5 inline-flex items-center justify-center rounded-full p-2.5 text-lime-500 bg-slate-800 shadow-2xl"
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <HashLink key={item.name} to={item.href} className="text-sm font-semibold leading-6 text-slate-50 hover:text-lime-500">
                  {item.name}
                </HashLink>
              ))}
            </div>
            {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <HashLink to="#" className="text-sm font-semibold leading-6 text-slate-50">
                Log in <span aria-hidden="true">&rarr;</span>
              </HashLink>
            </div> */}
          </nav>
          <Transition.Root show={mobileMenuOpen} as={Fragment}>
            <Dialog onClose={setMobileMenuOpen} className="lg:hidden">
              <div className="fixed inset-0 z-50" />
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-slate-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-slate-50/10 opacity-95">
                  
                <div
            aria-hidden="true"
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"
          >
            
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            />
            </div>
                  
                  <div className="flex items-start justify-between">
                    <span className="-ml-1 p-1.5">
                      <span className="sr-only">Forsaken Souls Haunted Attraction</span>
                      <span onClick={() => setMobileMenuOpen(false)} className="flex flex-row items-center gap-x-5"><img
                        alt=""
                        src="/images/forsakensouls-shirt.png"
                        className="h-12 w-auto"
                      /><img
                      alt=""
                      src="/images/spookeasy-transparent.png"
                      className="h-8 w-auto"
                    /></span>
                    </span>
                    <button
                      type="button"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-m-2.5 inline-flex items-center justify-center rounded-full p-2.5 text-lime-500 bg-slate-800 shadow-2xl"
                      >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                  </div>
                  <div className="mt-6 mb-4 text-slate-50 text-lg ghoulish">
                    The Spookeasy Haunted House is <span className="text-lime-500">free</span> this year!
                  </div>
                  {/* <hr className="border-lime-500" /> */}
                  <div className="mt-2 flow-root">
                    <div className="-my-6 divide-y divide-slate-500/25">
                      <div className="space-y-2 py-6">
                        {navigation.map((item) => (
                          <HashLink
                            key={item.name}
                            to={item.href}
                            onClick={() => setMobileMenuOpen(false)}
                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-slate-50 hover:bg-slate-800 hover:text-lime-500"
                          >
                            {item.name}
                          </HashLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[calc(100%-30rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-50rem)]"
          >
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            />
          </div>
                </DialogPanel>
              </Transition.Child>
            </Dialog>
          </Transition.Root>
        </header>

        <div id="home" className="relative isolate">
        
          <div
            aria-hidden="true"
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"
          >
            
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            />
            
          </div>
          <div className="pt-16 sm:pt-16 lg:pt-32 pb-12 relative overflow-hidden">
          {/* <div className="pt-16 sm:pt-16 lg:pt-32 pb-24 relative overflow-hidden border-b-2 border-lime-500 shadow-2xl"> */}
          <img
            alt=""
            src="/images/bar.jpg"
            className="absolute inset-0 -z-10 h-full w-full object-cover blur-sm contrast-125 opacity-50 overflow-hidden"
          />
            <div className="mx-auto max-w-2xl ">
            <div className="text-center" id="logos">
            <HashLink to="/"><img src="/images/forsakensouls-transparent.png" alt="Forsaken Souls Haunted Attraction" /></HashLink>

              <h1 className="text-3xl text-slate-50 sm:text-4xl ghoulish pt-6 sm:pt-0 drop-shadow">
                Evansville 2024<span className="text-lime-500 mt-4 block">Halloween Calendar</span>
              </h1>
              
              {/* <center><img src="/images/spookeasy-shadow.png" alt="Spookeasy Haunted House" className="mt-12 max-w-xs sm:max-w-lg" /></center> */}
              <p id="description" className="mt-12 text-lg leading-8 text-slate-200 px-6 drop-shadow">
                Enjoy all the Halloween events around Evansville, WI!<br /><HashLink to="/#contact" className="text-indigo-400 hover:text-indigo-300">Contact Us</HashLink> to add your event.
              </p>
              {/* <HashLink to="#location" className="animate-bounce block">
                <ChevronDoubleDownIcon aria-hidden="true" className="h-6 w-6 text-lime-500 mx-auto mt-12 -mb-12" />
              </HashLink> */}
              {/* <div className="mt-10 flex items-center justify-center gap-x-6">
                <HashLink
                  to="#"
                  className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-slate-50 shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  Get started
                </HashLink>
                <HashLink to="#" className="text-sm font-semibold leading-6 text-slate-50">
                  Learn more <span aria-hidden="true">→</span>
                </HashLink>
              </div> */}
            </div>
            
          </div></div>

          <div id="faq" className="max-w-5xl mx-auto">
      <div className="mx-auto max-w-5xl px-6 pt-8">
      <div className="text-center text-slate-300 px-8 pb-8">
            
          </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {faq.map((item) => (
              <div key={item.question} onClick={() => {
                setFAQ(
                  faq.map((faqItem) => {
                    if (item.question === faqItem.question) {
                      return { ...faqItem, open: !faqItem.open }
                    } else {
                      return { ...faqItem }
                    }
                  })
                );
              }} className="text-sm lg:text-base h-fit bg-slate-600/50 p-5 sm:p-6 rounded-2xl sm:rounded-3xl text-left cursor-pointer">
                <div className="flex gap-x-2 w-full justify-between text-slate-100">
                    <span>
                    {item.img ? <div className="max-h-16 w-36 mb-4 flex"><img src={item.img} alt={item.question} className="self-start max-h-12 rounded-md" /></div> : <></>}
                      <span className="font-bold block pb-2">{item.question}</span>{item.date}<br />{item.time}</span>
                    {item.open ? <ChevronUpIcon className="w-6 h-6 inline" /> : <ChevronDownIcon className="w-6 h-6 inline" />}
                  </div>
                
                <div className={"pt-4 text-slate-300 " + (item.open ? "block" : "hidden") }>
                  {item.answer}
                  { item.link ? <HashLink to={item.link} target="_blank" rel="nofollow" onClick={(e) => e.stopPropagation()} className="block mt-4 text-lime-500 hover:text-lime-400">Learn more</HashLink> : <></> }
                </div>
                
              </div>
            ))}
          </div>

          </div>
        </div>
          
          <div className="pt-16 pb-12 text-center text-slate-400 px-8">
            &copy; 2024 Forsaken Souls Haunted Attraction, All Rights Reserved. &#x2022; <HashLink to="/privacy" className="text-indigo-400 hover:text-indigo-300">Privacy Policy</HashLink>
          </div>
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[calc(100%-50rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-50rem)]"
          >
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            />
          </div>
        </div>
      </div>
      </FacebookProvider> </main>
    </>
    
  );
}

export default Calendar;
